import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import Rating from '@/components/Rating'
import config from '../config/config.json'
import axiosPlugin from './plugins/axiosPlugin'
import Notification from './components/Notifications.vue'; // Corregido


const app = createApp(App);
library.add(faStar);
library.add(faXmark);
app.config.globalProperties.apiUrl = config[config.mode]?.apiUrl;

app.use(store);
app.use(router);
app.use(CoreuiVue);
app.use(axiosPlugin);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('DocsCallout', DocsCallout);
app.component('DocsExample', DocsExample);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Rating', Rating);

// Nueva componente de notificación
app.component('Notification', Notification);

// Función para mostrar notificaciones
app.config.globalProperties.showNotification = function (title,message, type = 'success') {
  const notificationInstance = app.mount(Notification, {
    show: true,
    title,
    message,
    notificationType: type,
  });

  // Agregar el componente al cuerpo del documento
  document.body.appendChild(notificationInstance.$el);

  // Esperar un tiempo y luego destruir el componente
  setTimeout(() => {
    notificationInstance.$destroy();
  }, 3000); // Cambia el tiempo según tus necesidades
};

app.mount('#app');

<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid class="qr-header-container">
      <!-- <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        QRcodies
      </CHeaderBrand>

      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav> -->
      <div class="qr-header-left">
        <div v-if="!sidebarVisible" @click="openSidebar" class="bars">
          <!-- <img :src="openSidebarArrow" class="cursor-pointer rotate-icon" @click="openSidebar" style="vertical-align: sub;" /> -->
          <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
          <svg fill="#000000" width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>bars</title>
            <path d="M2 8.749h28c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-28c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM30 15.25h-28c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h28c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM30 23.25h-28c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h28c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
          </svg>
        </div>
        <div
        :class="{ 'qr-header-allClients': true, 'margin-left-open-sidebar': !sidebarVisible }" v-if="mainPages.includes(this.$route.path)">
          {{currentPage}}
        </div>
        <div
          class="qr-header-allClients d-flex align-items-center" v-else>
          <div class="d-flex">
            <button
              style="border: none; background-color: #fff"
              class="d-flex"
              @click="() => {
                if (showTaskTemplateDetails) {
                  this.$store.commit('setShowTaskTemplateDetails', null)
                } else if (locationDetails) {
                  this.$store.commit('setLocationDetails', null)
                } else if (routesBackAdmin.includes(this.$route.path)) {
                  this.$router.push(selectBackButtonFunction[this.$route.path])
                } else {
                  this.$router.push(`/clients`)
                }
              }"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_569_14385)">
                  <path
                    d="M19 11.0007H7.82998L12.71 6.1207C13.1 5.7307 13.1 5.0907 12.71 4.7007C12.32 4.3107 11.69 4.3107 11.3 4.7007L4.70998 11.2907C4.31998 11.6807 4.31998 12.3107 4.70998 12.7007L11.3 19.2907C11.69 19.6807 12.32 19.6807 12.71 19.2907C13.1 18.9007 13.1 18.2707 12.71 17.8807L7.82998 13.0007H19C19.55 13.0007 20 12.5507 20 12.0007C20 11.4507 19.55 11.0007 19 11.0007Z"
                    fill="#565656"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_569_14385">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div class="d-flex">
            {{
              showTaskTemplateDetails
              ? showTaskTemplateDetails.name
              : locationDetails
              ? locationDetails.address
              : routesBackAdmin.includes(this.$route.path)
              ? selectTextBackButton[this.$route.path]
              : this.$store.state.clientNameDashboard
            }}
          </div>
        </div>
      </div>
      <div class="qr-header-right">
        <div
          v-if="!this.arrayRoutesNotShowSearchOfHeader.includes(this.$route.path)"
          class="qr-header-input"
        >
          <div class="qr-header-input-icon">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9167 9.6667H10.2584L10.025 9.4417C11.025 8.27503 11.5417 6.68337 11.2584 4.9917C10.8667 2.67503 8.93337 0.825033 6.60003 0.5417C3.07503 0.108366 0.108366 3.07503 0.5417 6.60003C0.825033 8.93337 2.67503 10.8667 4.9917 11.2584C6.68337 11.5417 8.27503 11.025 9.4417 10.025L9.6667 10.2584V10.9167L13.2084 14.4584C13.55 14.8 14.1084 14.8 14.45 14.4584C14.7917 14.1167 14.7917 13.5584 14.45 13.2167L10.9167 9.6667ZM5.9167 9.6667C3.8417 9.6667 2.1667 7.9917 2.1667 5.9167C2.1667 3.8417 3.8417 2.1667 5.9167 2.1667C7.9917 2.1667 9.6667 3.8417 9.6667 5.9167C9.6667 7.9917 7.9917 9.6667 5.9167 9.6667Z"
                fill="#8F8F8F"
              />
            </svg>
          </div>
          <CFormInput
            v-model="searchInputValue"
            type="text"
            placeholder="Search"
            autocomplete="search"
            class="qr-header-input-input"
          />
        </div>
        <div
          v-if="!this.arrayRoutesNotShowFilterOfHeader.includes(this.$route.path)"
          class="qr-header-filter header-filter"
          @click="this.$store.commit('setShowModalFilter', showModalFilter ? false : true)"
        >
          <div class="qr-filter-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.99993 2H12.9999L7.98993 8.3L2.99993 2ZM0.249934 1.61C2.26993 4.2 5.99993 9 5.99993 9V15C5.99993 15.55 6.44993 16 6.99993 16H8.99993C9.54993 16 9.99993 15.55 9.99993 15V9C9.99993 9 13.7199 4.2 15.7399 1.61C16.2499 0.95 15.7799 0 14.9499 0H1.03993C0.209934 0 -0.260066 0.95 0.249934 1.61Z"
                fill="#8F8F8F"
              />
            </svg>
          </div>
          <div class="qr-header-filter-text">Filter</div>
        </div>
        <div
          v-if="!this.arrayRoutesNotShowAddButton.includes(this.$route.path) && !routesBackAdmin.includes(this.$route.path)"
          class="qr-header-input-button"
        >
          <CButton
            class="qr-button-create"
            @click="this.$store.commit('setVisiblePostModal', true)"
            ><span class="qr-plus-icon">+</span>
            {{
              this.$route.path == '/employees'
                ? 'New employee'
                : !taskTemplateTab ? 'New Client' : showTaskTemplateDetails ? "New Task" : "New Task Template"
            }}</CButton
          >
        </div>
      </div>
    </CContainer>
    <!-- <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb'
// import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { mapState } from 'vuex'
import store from '../store/index'
import openSidebarIcon from '@/assets/icons/closeSidebar.svg'
import screenSize from '../utils/screenSize'
import './header.css'

export default {
  name: 'AppHeader',
  components: {},
  data() {
    return {
      clientEmployeId: '',
      locationEmployeeId: '',
      roomEmployeeId: '',
      openSidebarArrow: openSidebarIcon,
      routesBackAdmin: [
        '/clientDashboardEmployee/ClientsDashboard',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails',
      ],
      selectTextBackButton: {
        '/clientDashboardEmployee/ClientsDashboard': 'Back To Clients',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee': 'Back To Locations',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom': 'Back To Rooms',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails': 'Back To Task List',
      },
      selectBackButtonFunction: {
        '/clientDashboardEmployee/ClientsDashboard': '/ClientsEmployees',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee': `/clientDashboardEmployee/ClientsDashboard?clientId=${this.clientEmployeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee?clientId=${this.clientEmployeId}&locationId=${this.locationEmployeeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom?id=${this.roomEmployeeId}`,
      },
      arrayRoutesNotShowThingsOfHeader: ["/dashboard", "/clients/clientDashboard/clientDashboard", "/ClientsEmployees"],
      arrayRoutesNotShowAddButton: ["/dashboard", "/clients/clientDashboard/clientDashboard", "/ClientsEmployees"],
      arrayRoutesNotShowSearchOfHeader: ["/dashboard", "/clients/clientDashboard/clientDashboard"],
      arrayRoutesNotShowFilterOfHeader: ["/dashboard", "/clients/clientDashboard/clientDashboard", "/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee", "/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom", "/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails"],
      mainPages:["/clients/clients", "/dashboard" , "/employees", "/ClientsEmployees"]
    }
  },
  setup() {
    return {
      logo,
    }
  },
  computed: {
    ...mapState([
      'sidebarVisible',
      'taskTemplateTab',
      'showTaskTemplateDetails',
      'showModalFilter',
      'locationDetails',
      'clientEmploye',
      'currentPage',
      'locationEmployee',
      'roomEmployee',
    ]),
    searchInputValue: {
      get() {
        return this.$store.state.searchInputValue;
      },
      set(value) {
        this.$store.commit('setSearchInputValue', value);
      },
    },
  },
  methods: {
    resetAllData() {
      this.$store.commit('setVisiblePostModal', false);
      this.$store.commit('setSearchInputValue', '');
      this.$store.commit('setTaskTemplateTab', false);
      this.$store.commit('setShowTaskTemplateDetails', null);
      this.$store.commit('setShowModalFilter', false);
      this.$store.commit('setLocationDetails', null);
      this.$store.commit('setVisibleDeleteModal', false)
    },
    openSidebar() {
      store.commit({ type: 'updateSidebarVisible', value: true })
    },

  },
  created() {
    // Restablecer el valor de searchInputValue al cargar la página
    this.$store.commit('setLocationEmployee', '');
    this.$store.commit('setClientEmploye', '');
    this.$store.commit('setRoomEmployee', '');
    this.resetAllData();
  },
  watch: {
    // Este watcher escucha cambios en la ruta
    $route() {
      // Restablecer el valor de searchInputValue al cambiar la ruta
      this.resetAllData();

      if(screenSize().width<500){
        this.$store.commit({ type: 'updateSidebarVisible', value: false })
        console.log(this.$store.state.sidebarVisible)
      }
    },
    clientEmploye(newValue) {
      if (newValue) {
        this.clientEmployeId = newValue
        this.selectBackButtonFunction = {
        '/clientDashboardEmployee/ClientsDashboard': '/ClientsEmployees',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee': `/clientDashboardEmployee/ClientsDashboard?clientId=${newValue}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee?clientId=${newValue}&locationId=${this.locationEmployeeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom?id=${this.roomEmployeeId}`,
        }
      }
    },
    locationEmployee(newValue) {
      if (newValue) {
        this.locationEmployeeId = newValue
        this.selectBackButtonFunction = {
        '/clientDashboardEmployee/ClientsDashboard': '/ClientsEmployees',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee': `/clientDashboardEmployee/ClientsDashboard?clientId=${this.clientEmployeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee?clientId=${this.clientEmployeId}&locationId=${newValue}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom?id=${this.roomEmployeeId}`,
        }
      }
    },
    roomEmployee(newValue) {
      if (newValue) {
        this.roomEmployeeId = newValue
        this.selectBackButtonFunction = {
        '/clientDashboardEmployee/ClientsDashboard': '/ClientsEmployees',
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee': `/clientDashboardEmployee/ClientsDashboard?clientId=${this.clientEmployeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee?clientId=${this.clientEmployeId}&locationId=${this.locationEmployeeId}`,
        '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails': `/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom?id=${newValue}`,
        }
      }
    }
  },
  mounted() {
    // Restablecer el valor de searchInputValue al montar el componente
    this.resetAllData();
  },
}
</script>

<style>
.qr-header-filter {
  cursor: pointer
}
</style>
